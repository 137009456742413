<template>
  <v-list-item :input-value="!notification.dismissed" clickable class="portal--notification">
    <div
      key="main"
      class="d-flex justify-between align-center w-100"
      style="width: 100%; height: 100%; border-radius: inherit"
    >
      <div class="color-block" v-if="!dismissing" />
      <v-list-item-content class="d-flex align-center">
        <v-list-item-subtitle
          v-text="formatNotificationTime(notification.createdAt)"
          class="overline mb-2"
        ></v-list-item-subtitle>
        <v-list-item-title> {{ notification.message }} </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon class="d-flex mr-n3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="handleDismiss" icon v-on="on" v-bind="attrs">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Dismiss</span>
        </v-tooltip>
      </v-list-item-icon>
    </div>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="dismissing"
        class="d-flex justify-center align-center dismissed--wrap pa-2 px-4"
        key="dismissing"
      >
        <v-list-item-title> Notification dismissed. </v-list-item-title>
        <v-list-item-icon class="d-flex align-center flex-grow-1 mr-3">
          <v-btn text color="error" class="px-2" @click="cancelDismiss">Undo</v-btn>
        </v-list-item-icon>
      </div>
    </v-slide-x-transition>
  </v-list-item>
</template>
<style lang="scss" scoped>
.portal--notification {
  .v-list-item__icon {
    height: max-content;
    margin: auto;
  }
  .v-btn {
    font-size: 0.7em;
    padding-left: 4px;
    padding-right: 4px;
  }
}
.dismissed--wrap {
  background-color: rgba(53, 52, 52, 1);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: inherit;
  color: white;
}
.color-block {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background-color: var(--v-primary-lighten1);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
</style>
<script>
export default {
  data() {
    return {
      dismissing: false,
      gracePeriodMs: 3000,
      timeout: null,
    };
  },
  props: {
    dismissNotification: Function,
    notification: Object,
  },
  methods: {
    cancelDismiss() {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.dismissing = false;
    },

    handleDismiss() {
      this.dismissing = true;
      this.timeout = setTimeout(() => {
        this.dismissNotification();
      }, this.gracePeriodMs);
    },
    formatNotificationTime(time) {
      return this.$store.getters.toTimezone(time).fromNow();
    },
  },
};
</script>
